<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <template v-if="!loading">
          <ak-input-number
            required="true"
            v-model="this.price.valueDouble"
            label="Prix"
            :validator="v$.price"
            mode="currency"
            :submitted="this.submitted"
          ></ak-input-number>
        </template>
        <div class="row">
          <div class="col-12">
            <Button
              label="Modifier"
              class="button-custom button-info p-2"
              @click="updatePrice()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import VariableService from "@services/VariableService";
import AkInputNumber from "@components/input/AkInputNumber";

export default {
  components: { AkInputNumber },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      loading: true,
      price: null,
      submitted: false,
    };
  },

  validations() {
    return {};
  },

  variableService: null,
  created() {
    this.variableService = new VariableService();
  },

  mounted() {
    this.variableService.variable("price").then((data) => {
      this.price = data;
      this.loading = false;
    });
  },
  methods: {
    updatePrice() {
      this.variableService.update(this.price);
      this.$toast.add({
        severity: "success",
        summary: "Terminé",
        detail: "Le prix a bien été modifié",
        life: 3000,
      });
    },
  },
};
</script>

<style scoped></style>
